import type { TooltipProps } from '@chakra-ui/react';
import { Skeleton, Flex, useColorModeValue, chakra } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import React from 'react';

import Hint from 'ui/shared/Hint';
import type { IconName } from 'ui/shared/IconSvg';
import IconSvg from 'ui/shared/IconSvg';

type Props = {
  icon: IconName;
  title: string;
  value: string;
  className?: string;
  tooltipLabel?: React.ReactNode;
  url?: string;
  isLoading?: boolean;
};

// const LARGEST_BREAKPOINT = '1240px';

const TOOLTIP_PROPS: Partial<TooltipProps> = {
  hasArrow: false,
  borderRadius: '4px',
  placement: 'bottom-end',
  offset: [0, 0],
};

const StatsItem = ({
  icon,
  title,
  value,
  className,
  tooltipLabel,
  url,
  isLoading,
}: Props) => {
  // const sxContainer: SystemStyleObject = {
  //   [`@media screen and (min-width: ${ breakpoints.lg }) and (max-width: ${ LARGEST_BREAKPOINT })`]: { flexDirection: 'column' },
  // };
  //
  // const sxText: SystemStyleObject = {
  //   [`@media screen and (min-width: ${ breakpoints.lg }) and (max-width: ${ LARGEST_BREAKPOINT })`]: { alignItems: 'center' },
  // };

  const bgColor = useColorModeValue('#9e9e9e1f', '#1A1A1A');
  const borderBGColor = useColorModeValue(
    'linear-gradient(90deg, #fbf9f9, #fbf9f9)',
    'linear-gradient(90deg, #4f4f4f66, #a5a5a566)'
  );
  const borderColor = useColorModeValue('#1a1a1a14', 'transparent');
  const iconColor = useColorModeValue('#505050', '#ffffff');
  const titleColor = useColorModeValue('#1a1a1a99', '#ffffff99');
  const valueColor = useColorModeValue('#1a1a1a', '#ffffff');

  return (
    <Flex bgGradient={borderBGColor} borderRadius="4px">
      <Flex
        width="100%"
        m="1px"
        borderWidth="1px"
        borderColor={borderColor}
        backgroundColor={bgColor}
        padding={3}
        borderRadius="4px"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className={className}
        position="relative"
        {...(url && !isLoading
          ? {
              as: 'a',
              href: url,
            }
          : {})}
      >
        <Flex alignItems="center" columnGap={3} rowGap={2}>
          <IconSvg
            name={icon}
            boxSize={7}
            isLoading={isLoading}
            color={iconColor}
          />
          <Flex flexDirection="column" alignItems="start">
            <Skeleton
              isLoaded={!isLoading}
              color={titleColor}
              fontSize="xs"
              lineHeight="16px"
              borderRadius="base"
            >
              <span>{title}</span>
            </Skeleton>
            <Skeleton
              isLoaded={!isLoading}
              fontWeight={500}
              fontSize="md"
              color={valueColor}
              borderRadius="base"
            >
              <span>{value}</span>
            </Skeleton>
          </Flex>
        </Flex>
        {tooltipLabel && !isLoading && (
          <Hint label={tooltipLabel} tooltipProps={TOOLTIP_PROPS} />
        )}
      </Flex>
    </Flex>
  );
};

export default chakra(StatsItem);
