import { Flex, Skeleton, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import useApiQuery from 'lib/api/useApiQuery';
import Hint from 'ui/shared/Hint';

import IconSvg, { IconName } from '../../shared/IconSvg';
import ChainIndicatorChartContainer from './ChainIndicatorChartContainer';
import ChainIndicatorItem from './ChainIndicatorItem';
import useFetchChartData from './useFetchChartData';
import INDICATORS from './utils/indicators';

const indicators = INDICATORS.filter(({ id }) =>
  config.UI.homepage.charts.includes(id)
).sort((a, b) => {
  if (
    config.UI.homepage.charts.indexOf(a.id) >
    config.UI.homepage.charts.indexOf(b.id)
  ) {
    return 1;
  }

  if (
    config.UI.homepage.charts.indexOf(a.id) <
    config.UI.homepage.charts.indexOf(b.id)
  ) {
    return -1;
  }

  return 0;
});

const ChainIndicators = () => {
  const [selectedIndicator, handleSelect] = React.useState(indicators[0]?.id);
  const indicator = indicators.find(({ id }) => id === selectedIndicator);

  const queryResult = useFetchChartData(indicator);
  const statsQueryResult = useApiQuery('homepage_stats');

  const listBgColor = useColorModeValue('#9e9e9e14', '#1A1A1A');

  const bgColor = useColorModeValue('#F8F8F8', '#232323');
  const borderBGColor = useColorModeValue(
    'linear-gradient(90deg, #fbf9f9, #fbf9f9)',
    'linear-gradient(90deg, #4f4f4f66, #a5a5a566)'
  );
  const borderColor = useColorModeValue('#1a1a1a14', 'transparent');
  const titleColor = useColorModeValue('#1a1a1a', '#ffffff');

  const iconBgColor = useColorModeValue('#1A1A1A33', '#505050');
  const iconColor = useColorModeValue('#1A1A1A99', '#eeeeee99');

  if (indicators.length === 0) {
    return null;
  }

  const valueTitle = (() => {
    if (statsQueryResult.isPending) {
      return <Skeleton h="48px" w="215px" mt={3} mb={4} />;
    }

    if (statsQueryResult.isError) {
      return (
        <Text mt={3} mb={4}>
          There is no data
        </Text>
      );
    }

    return (
      <Text
        fontWeight={600}
        fontSize="48px"
        lineHeight="48px"
        mt={3}
        mb={4}
        color={titleColor}
      >
        {indicator?.value(statsQueryResult.data)}
      </Text>
    );
  })();

  return (
    <Flex bgGradient={borderBGColor} borderRadius="4px" mt={8}>
      <Flex
        p={{ base: 0, lg: 8 }}
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="4px"
        bgColor={bgColor}
        columnGap={12}
        rowGap={0}
        m="1px"
        flexDir={{ base: 'column', lg: 'row' }}
        w="100%"
        alignItems="stretch"
      >
        <Flex
          flexGrow={1}
          flexDir="column"
          order={{ base: 2, lg: 1 }}
          p={{ base: 6, lg: 0 }}
        >
          <Flex alignItems="center" gap={2}>
            <IconSvg
              name={indicator?.icon as IconName}
              boxSize={6}
              bgColor={iconBgColor}
              color={iconColor}
              borderRadius={indicator?.icon === 'bfc_price' ? '12px' : '4px'}
            />
            <Text fontWeight={500} fontSize="lg" color={titleColor}>
              {indicator?.title}
            </Text>
            {indicator?.hint && <Hint label={indicator.hint} />}
          </Flex>
          {valueTitle}
          <ChainIndicatorChartContainer {...queryResult} />
        </Flex>
        {indicators.length > 1 && (
          <Flex
            flexShrink={0}
            flexDir="column"
            as="ul"
            p={3}
            h="fit-content"
            borderRadius="4px"
            bgColor={listBgColor}
            rowGap={3}
            order={{ base: 1, lg: 2 }}
          >
            {indicators.map((indicator) => (
              <ChainIndicatorItem
                key={indicator.id}
                {...indicator}
                isSelected={selectedIndicator === indicator.id}
                onClick={handleSelect}
                stats={statsQueryResult}
              />
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default ChainIndicators;
