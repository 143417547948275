import {
  Box,
  Heading,
  Flex,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';

import { route } from 'nextjs-routes';
import React from 'react';

import config from 'configs/app';
import useApiQuery, { getResourceKey } from 'lib/api/useApiQuery';
import useIsMobile from 'lib/hooks/useIsMobile';
import { nbsp } from 'lib/html-entities';
import type { SocketMessage } from 'lib/socket/types';
import useSocketChannel from 'lib/socket/useSocketChannel';
import useSocketMessage from 'lib/socket/useSocketMessage';
import { BLOCK } from 'stubs/block';
import { HOMEPAGE_STATS } from 'stubs/stats';
import type { Block } from 'types/api/block';
import LinkInternal from 'ui/shared/LinkInternal';

import LatestBlocksItem from './LatestBlocksItem';

const LatestBlocks = () => {
  const linkColor = useColorModeValue('#4466A8', '#749BFF');
  const titleColor = useColorModeValue('#1a1a1a', '#ffffff');
  const isMobile = useIsMobile();
  // const blocksMaxCount = isMobile ? 2 : 3;
  let blocksMaxCount: number;
  if (
    config.features.optimisticRollup.isEnabled ||
    config.UI.views.block.hiddenFields?.total_reward
  ) {
    blocksMaxCount = isMobile ? 4 : 5;
  } else {
    blocksMaxCount = isMobile ? 2 : 3;
  }
  const { data, isPlaceholderData, isError } = useApiQuery('homepage_blocks', {
    queryOptions: {
      placeholderData: Array(blocksMaxCount).fill(BLOCK),
    },
  });

  const queryClient = useQueryClient();
  const statsQueryResult = useApiQuery('homepage_stats', {
    queryOptions: {
      placeholderData: HOMEPAGE_STATS,
    },
  });

  const handleNewBlockMessage: SocketMessage.NewBlock['handler'] =
    React.useCallback(
      (payload) => {
        queryClient.setQueryData(
          getResourceKey('homepage_blocks'),
          (prevData: Array<Block> | undefined) => {
            const newData = prevData ? [...prevData] : [];

            if (
              newData.some((block) => block.height === payload.block.height)
            ) {
              return newData;
            }

            return [payload.block, ...newData]
              .sort((b1, b2) => b2.height - b1.height)
              .slice(0, blocksMaxCount);
          }
        );
      },
      [queryClient, blocksMaxCount]
    );

  const channel = useSocketChannel({
    topic: 'blocks:new_block',
    isDisabled: isPlaceholderData || isError,
  });
  useSocketMessage({
    channel,
    event: 'new_block',
    handler: handleNewBlockMessage,
  });

  let content;

  if (isError) {
    content = <Text>No data. Please reload page.</Text>;
  }

  if (data) {
    const dataToShow = data.slice(0, blocksMaxCount);

    content = (
      <>
        <VStack spacing={3} mb={4} overflow="hidden" alignItems="stretch">
          <AnimatePresence initial={false}>
            {dataToShow.map((block, index) => (
              <LatestBlocksItem
                key={block.height + (isPlaceholderData ? String(index) : '')}
                block={block}
                isLoading={isPlaceholderData}
              />
            ))}
          </AnimatePresence>
        </VStack>
        <Flex justifyContent="center">
          <LinkInternal fontSize="sm" href={route({ pathname: '/blocks' })}>
            View all blocks
          </LinkInternal>
        </Flex>
      </>
    );
  }

  return (
    <Box width={{ base: '100%', lg: '280px' }} flexShrink={0}>
      <Heading
        as="h4"
        color={titleColor}
        fontSize="20px"
        fontWeight={700}
        mb={4}
      >
        Latest Blocks
      </Heading>
      {content}
    </Box>
  );
};

export default LatestBlocks;
