import { Text, Flex, Box, Skeleton, useColorModeValue } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import type { UseQueryResult } from '@tanstack/react-query';
import React from 'react';

import type { ResourceError } from 'lib/api/resources';
import useIsMobile from 'lib/hooks/useIsMobile';
import type { HomeStats } from 'types/api/stats';
import type { ChainIndicatorId } from 'types/homepage';
import IconSvg, { IconName } from '../../shared/IconSvg';

interface Props {
  id: ChainIndicatorId;
  title: string;
  value: (stats: HomeStats) => string;
  icon: IconName;
  isSelected: boolean;
  onClick: (id: ChainIndicatorId) => void;
  stats: UseQueryResult<HomeStats, ResourceError<unknown>>;
}

const ChainIndicatorItem = ({
  id,
  title,
  value,
  icon,
  isSelected,
  onClick,
  stats,
}: Props) => {
  const isMobile = useIsMobile();

  const activeBgColor = useColorModeValue('#ffffff', '#232323');
  const titleColor = useColorModeValue('#1a1a1a99', '#ffffff99');
  const valueColor = useColorModeValue('#1a1a1a', '#ffffff');
  const bgColor = useColorModeValue('#1A1A1A33', '#505050');
  const color = useColorModeValue('#1A1A1A99', '#eeeeee99');

  const handleClick = React.useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  const valueContent = (() => {
    if (isMobile) {
      return null;
    }

    if (stats.isPending) {
      return (
        <Skeleton
          h={3}
          w="70px"
          my={1.5}
          // ssr: isMobile = undefined, isLoading = true
          display={{ base: 'none', lg: 'block' }}
        />
      );
    }

    if (stats.isError) {
      return (
        <Text variant="secondary" fontWeight={400}>
          no data
        </Text>
      );
    }

    return (
      <Text size="16px" fontWeight={600} color={valueColor}>
        {value(stats.data)}
      </Text>
    );
  })();

  return (
    <Flex
      alignItems="center"
      columnGap={3}
      p={4}
      as="li"
      borderRadius="4px"
      cursor="pointer"
      onClick={handleClick}
      bgColor={isSelected ? activeBgColor : 'transparent'}
      zIndex={isSelected ? 1 : 'initial'}
      _hover={{
        activeBgColor,
        zIndex: 1,
      }}
    >
      <IconSvg
        name={icon}
        boxSize={6}
        bgColor={bgColor}
        color={color}
        borderRadius={icon === 'bfc_price' ? '12px' : '4px'}
      />
      <Box>
        <Text fontWeight={600} color={titleColor}>
          {title}
        </Text>
        {valueContent}
      </Box>
    </Flex>
  );
};

export default React.memo(ChainIndicatorItem);
