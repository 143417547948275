import { Box, Heading, Flex, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import LatestBlocks from 'ui/home/LatestBlocks';
import LatestZkEvmL2Batches from 'ui/home/LatestZkEvmL2Batches';
import Stats from 'ui/home/Stats';
import Transactions from 'ui/home/Transactions';
import ChainIndicators from 'ui/home/indicators/ChainIndicators';
import SearchBar from 'ui/snippets/searchBar/SearchBar';

const Home = () => {
  const bgColor = useColorModeValue('transparent', '#1A1A1A99');
  const bgGradientColor = useColorModeValue(
    'linear-gradient(90deg, #1A1A1A99, #1A1A1A14)',
    'linear-gradient(90deg, #1A1A1A33, #FFFFFF33)'
  );
  const borderBGColor = useColorModeValue(
    'linear-gradient(90deg, #fbf9f9, #fbf9f9)',
    'linear-gradient(90deg, #4f4f4f66, #a5a5a566)'
  );
  const borderColor = useColorModeValue('#1a1a1a14', 'transparent');
  const titleColor = useColorModeValue('#1a1a1a', '#ffffff');

  return (
    <Box as="main">
      <Flex bgGradient={borderBGColor} borderRadius="4px">
        <Box
          backgroundColor={bgColor}
          bgGradient={bgGradientColor}
          w="100%"
          backgroundImage="/static/searchbarBG.png"
          backgroundPosition="right 25%"
          backgroundRepeat="no-repeat"
          backgroundSize="40%"
          borderWidth="1px"
          borderColor={borderColor}
          borderRadius="4px"
          m="1px"
          padding={{ base: '24px', lg: '48px' }}
          paddingBottom={{ base: '24px', lg: '60px' }}
          minW={{ base: 'unset', lg: '900px' }}
          data-label="hero plate"
        >
          <Flex
            mb={{ base: 6, lg: 8 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading
              as="h1"
              size={{ base: 'md', lg: 'xl' }}
              lineHeight={{ base: '32px', lg: '50px' }}
              fontWeight={700}
              color={titleColor}
            >
              Bifrost Explorer
            </Heading>
          </Flex>
          <Flex maxWidth="872px">
            <SearchBar isHomepage />
          </Flex>
        </Box>
      </Flex>
      <Stats />
      <ChainIndicators />
      <Flex
        mt={8}
        direction={{ base: 'column', lg: 'row' }}
        columnGap={12}
        rowGap={8}
      >
        {config.features.zkEvmRollup.isEnabled ? (
          <LatestZkEvmL2Batches />
        ) : (
          <LatestBlocks />
        )}
        <Box flexGrow={1}>
          <Transactions />
        </Box>
      </Flex>
    </Box>
  );
};

export default Home;
