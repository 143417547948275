import { Alert, AlertDescription, chakra } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import React from 'react';

interface DataFetchAlertProps {
  className?: string;
  message?: string;
}

const DataFetchAlert = ({ className, message }: DataFetchAlertProps) => (
  <Alert status="warning" width="fit-content" className={className}>
    <AlertDescription>
      {message ||
        'Something went wrong. Try refreshing the page or come back later.'}
    </AlertDescription>
  </Alert>
);

export default chakra(DataFetchAlert);
