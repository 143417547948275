import { TooltipProps, useColorModeValue } from '@chakra-ui/react';
import {
  chakra,
  IconButton,
  Tooltip,
  useDisclosure,
  Skeleton,
} from '@chakra-ui/react';
import React from 'react';

import IconSvg from 'ui/shared/IconSvg';

interface Props {
  label: string | React.ReactNode;
  className?: string;
  tooltipProps?: Partial<TooltipProps>;
  isLoading?: boolean;
}

const Hint = ({ label, className, tooltipProps, isLoading }: Props) => {
  // have to implement controlled tooltip because of the issue - https://github.com/chakra-ui/chakra-ui/issues/7107
  const {
    isOpen,
    onOpen: handleOpen,
    onToggle,
    onClose: handleClose,
  } = useDisclosure();
  const tooltipBgColor = useColorModeValue('#ffffff', '#282828');

  const handleClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onToggle();
    },
    [onToggle]
  );

  if (isLoading) {
    return <Skeleton className={className} boxSize={5} borderRadius="sm" />;
  }

  return (
    <Tooltip
      label={label}
      placement="top"
      maxW="320px"
      isOpen={isOpen}
      backgroundColor={tooltipBgColor}
      {...tooltipProps}
    >
      <IconButton
        aria-label="hint"
        icon={<IconSvg name="info" fill="white" w="100%" h="100%" />}
        boxSize="15px"
        variant="simple"
        colorScheme="gray"
        flexShrink={0}
        className={className}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        onClick={handleClick}
      />
    </Tooltip>
  );
};

export default React.memo(chakra(Hint));
